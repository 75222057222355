import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from "vue";
import SupplyChainModule from "@ems/containers/SupplyChainManagement/SupplyChainManament.module";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { ElMessage } from "element-plus/lib/components/message";
import { isEmpty } from "lodash";
import DialogVerifyVue from "@/components/TheDialog/DialogVerify.vue";
import DialogModule from "@/store/UI/Dialog";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'InviteBulk.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

const selectedFile = ref({} as any);
const isStep1 = ref(true);
const dataInvitedList = ref([] as any);
const uploadedFile = ref();

const isLoadingDownloadTemplate = computed(
  () => SupplyChainModule.isLoadingDownloadTemplateGetter
);

const isLoadingVerifyFile = computed(
  () => SupplyChainModule.isLoadingVerifyFileGetter
);

const isEnableInvite = computed(
  () =>
    !isEmpty(
      dataInvitedList.value.find((item: any) => item.Errors.length !== 0)
    )
);

const handleNext = async () => {
  if (!isEmpty(selectedFile.value)) {
    const formData = new FormData();
    formData.append("file", selectedFile.value.raw);
    dataInvitedList.value = await SupplyChainModule.verifyInvitedFile(formData);
  }

  if (dataInvitedList.value.status) {
    ElMessage.error(dataInvitedList.value.message);
    selectedFile.value = {};
  } else {
    isStep1.value = false;
  }
};

const handleBackStep1 = () => {
  DialogModule.showModal({
    component: DialogVerifyVue,
    data: {
      title: "Do you want to upload other file?",
      description: "This action will clear your data. Do you want to continue?",
    },
    rest: handleConfirmBack,
  });
};

const handleBulkInvitation = async () => {
  await SupplyChainModule.sendInvitation(dataInvitedList.value);
  router.push("/supply-chain-management");
};

const handleConfirmBack = () => {
  isStep1.value = true;
  selectedFile.value = {};
};

const onChangeFile = (file: any) => {
  selectedFile.value = file;
};

const handleExceed = (file: any, res: any) => {
  uploadedFile.value.handleRemove(res);
  selectedFile.value = {};
  const fileExceed = file[0];
  uploadedFile.value!.handleStart(fileExceed);
};

const handleDownloadTemplate = async () => {
  const downloadContent = await SupplyChainModule.downloadInvitationTemplate();
  const anchor = document.createElement("a");
  anchor.href = downloadContent.fileContent;
  anchor.click();
};

const handleRemoveUploadedFile = () => {
  selectedFile.value = {};
};

const __returned__ = { router, selectedFile, isStep1, dataInvitedList, uploadedFile, isLoadingDownloadTemplate, isLoadingVerifyFile, isEnableInvite, handleNext, handleBackStep1, handleBulkInvitation, handleConfirmBack, onChangeFile, handleExceed, handleDownloadTemplate, handleRemoveUploadedFile, computed, ref, get SupplyChainModule() { return SupplyChainModule }, BaseSvgIcon, get ElMessage() { return ElMessage }, get isEmpty() { return isEmpty }, DialogVerifyVue, get DialogModule() { return DialogModule }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})