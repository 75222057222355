import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "el-icon el-icon--upload" }
const _hoisted_3 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 1,
  class: "box py-5"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return ($setup.isStep1)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mt-5" }, "Download Template", -1)),
        _createElementVNode("button", {
          class: "btn btn--green-primary my-4",
          onClick: $setup.handleDownloadTemplate
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: "inline-block text-white hover:text-blue-400 w-5 h-5",
            name: "download-file"
          }),
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "ml-5" }, "Download", -1))
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("p", null, " Please convert the file to csv before uploading. Select “Ok” for the Excel popup message. ", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("p", null, "Upload completed file here:", -1)),
        _createVNode(_component_el_upload, {
          class: _normalizeClass(_ctx.styles['custom-upload-file']),
          drag: "",
          limit: 1,
          "auto-upload": false,
          file: "",
          "on-change": (file) => $setup.onChangeFile(file),
          "on-remove": $setup.handleRemoveUploadedFile,
          "on-exceed": (file, res) => $setup.handleExceed(file, res),
          ref: "uploadedFile"
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", _hoisted_2, [
              _createVNode($setup["BaseSvgIcon"], {
                class: "inline-block group-hover:text-indino-primary w-6 h-6",
                name: "UploadFile"
              })
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "el-upload__text" }, "Please upload your files here", -1))
          ]),
          _: 1
        }, 8, ["class", "on-change", "on-exceed"]),
        _cache[5] || (_cache[5] = _createElementVNode("p", null, " *Our system currently is able to invite up to 100 partners at one time ", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "btn btn--green-primary",
            onClick: $setup.handleNext,
            disabled: $setup.isEmpty($setup.selectedFile)
          }, " Next ", 8, _hoisted_4)
        ])
      ])), [
        [_directive_loading, $setup.isLoadingDownloadTemplate || $setup.isLoadingVerifyFile]
      ])
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex justify-between mb-6" }, [
          _createElementVNode("p", { class: "text-xl 2xl:text-2xl" }, "Invite List")
        ], -1)),
        _createVNode(_component_el_table, {
          class: "w-full table-custom",
          data: $setup.dataInvitedList,
          stripe: "",
          "cell-class-name": "text-cafe-dark",
          "header-row-class-name": "tableHeaderRow",
          "row-class-name": "tableRow"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "CompanyName",
              label: "Company Name"
            }),
            _createVNode(_component_el_table_column, {
              prop: "CountryName",
              label: "Country"
            }),
            _createVNode(_component_el_table_column, {
              prop: "ContactName",
              label: "Person Of Contact name"
            }),
            _createVNode(_component_el_table_column, {
              prop: "Email",
              label: "Person Of Contact Email"
            }),
            _createVNode(_component_el_table_column, { label: "Valid Entry" }, {
              default: _withCtx((scope) => [
                ($setup.isEmpty(scope.row.Errors))
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Able to Invite"))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(scope.row.Errors, (item) => {
                      return (_openBlock(), _createElementBlock("p", {
                        key: item,
                        class: "text-red-500"
                      }, _toDisplayString(item), 1))
                    }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"]),
        _cache[7] || (_cache[7] = _createElementVNode("hr", { class: "border-gray-400 my-5" }, null, -1)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            class: "btn btn--black-primary w-32",
            onClick: $setup.handleBackStep1
          }, " Back "),
          _createElementVNode("button", {
            class: "btn btn--green-primary ml-5 w-32",
            onClick: $setup.handleBulkInvitation,
            disabled: $setup.isEnableInvite
          }, " Invite ", 8, _hoisted_8)
        ])
      ]))
}