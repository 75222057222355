import { defineComponent as _defineComponent } from 'vue'
import DialogModule from "@/store/UI/Dialog";

export default /*@__PURE__*/_defineComponent({
  __name: 'DialogVerify',
  setup(__props, { expose: __expose }) {
  __expose();

const initialValues =
  DialogModule.getData ||
  ({
    title: "Please input title",
    description: "",
  } as any);

const handleClose = () => {
  DialogModule.hideModal();
};

const handleConfirm = () => {
  const { rest } = DialogModule.getRest as any;
  rest();
  DialogModule.hideModal();
};

const __returned__ = { initialValues, handleClose, handleConfirm, get DialogModule() { return DialogModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})