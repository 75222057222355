import { defineComponent as _defineComponent } from 'vue'
import { useHash, computeTabName } from "@/utils/hooks";
import Invite from "@ems/containers/SupplyChainManagement/TheForm/Invite.Form.vue";
import InviteBulk from "@ems/containers/SupplyChainManagement/TheForm/InviteBulk.Form.vue";
import {
  HASH_SUPPLY_CHAIN_MANAGEMENT,
  TAB_SUPPLY_CHAIN_MANAGEMENT,
} from "@ems/constants";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvitePartner',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

useHash(HASH_SUPPLY_CHAIN_MANAGEMENT);
const handleTabClick = (tabs: string) => {
  router.replace({ hash: `#${tabs}` });
};

const __returned__ = { route, router, handleTabClick, get useHash() { return useHash }, get computeTabName() { return computeTabName }, Invite, InviteBulk, get HASH_SUPPLY_CHAIN_MANAGEMENT() { return HASH_SUPPLY_CHAIN_MANAGEMENT }, get TAB_SUPPLY_CHAIN_MANAGEMENT() { return TAB_SUPPLY_CHAIN_MANAGEMENT }, get useRoute() { return useRoute }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})