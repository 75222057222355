import { defineComponent as _defineComponent } from 'vue'
import { Field, Form } from "vee-validate";
import { computed, onMounted, ref, watch } from "vue";
import { object, string } from "yup";
import SupplyChainModule from "@ems/containers/SupplyChainManagement/SupplyChainManament.module";
import {
  IInvitePartnerDTO,
  ICompanyNameVerificationDTO,
} from "@/models/SupplyChainManagement";
import { useRouter } from "vue-router";
import debounce from "lodash/debounce";
import MetadataModule from "@/store/modules/Metadata";
import { ICountries } from "@/models/Metadata";


export default /*@__PURE__*/_defineComponent({
  __name: 'Invite.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const allCountries = computed(() => MetadataModule.allCountriesGetter);
const router = useRouter();

const schema = object({
  CompanyName: string().required().label("Company Name"),
  ContactName: string().required().label("Contact Name"),
  Email: string().required().email().label("Email"),
  CountryContinentId: string().required().label("Country"),
});

const warningMessageRef = ref("");

const onSubmit = async (values: any) => {
  const data = { ...values, CountryId: values.CountryContinentId };
  await SupplyChainModule.invitePartner(data as IInvitePartnerDTO);
  if (!SupplyChainModule.hasInvitePartnerErrorMsg) {
    router.push("/supply-chain-management");
  }
};

const options = ref<ICountries[]>([]);
const remoteMethod = (query: string) => {
  if (query) {
    setTimeout(() => {
      options.value = allCountries.value.filter((item) => {
        return item.Name.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else if (query === "") {
    options.value = allCountries.value;
  } else {
    options.value = [];
  }
};
watch(allCountries, () => {
  filterOptionCountry();
});
onMounted(() => {
  filterOptionCountry();
});
const filterOptionCountry = () => {
  options.value = allCountries.value.map((item) => {
    return { Value: `${item.Value}`, Name: `${item.Name}` };
  });
};

const onValidateCompanyName = debounce(async (value, field, meta) => {
  const data = {
    Name: field.value,
  } as ICompanyNameVerificationDTO;
  if (meta.valid && value !== "") {
    const messageError = await SupplyChainModule.verificationCompanyName(data);
    if (messageError) {
      warningMessageRef.value = messageError;
    }
    return;
  }
  warningMessageRef.value = "";
}, 100);

onMounted(() => {
  MetadataModule.getAllCountryAction();
});

const __returned__ = { allCountries, router, schema, warningMessageRef, onSubmit, options, remoteMethod, filterOptionCountry, onValidateCompanyName, get Field() { return Field }, get Form() { return Form }, computed, onMounted, ref, watch, get object() { return object }, get string() { return string }, get SupplyChainModule() { return SupplyChainModule }, get IInvitePartnerDTO() { return IInvitePartnerDTO }, get ICompanyNameVerificationDTO() { return ICompanyNameVerificationDTO }, get useRouter() { return useRouter }, get debounce() { return debounce }, get MetadataModule() { return MetadataModule }, get ICountries() { return ICountries } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})